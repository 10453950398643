import axios from 'axios'
import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import columnsUtils from '../../utils/columnsUtils'
import FilterOption from '../components/FilterOption.vue'
import {
  GridApi,
  SortChangedEvent,
  ColumnApi,
  ColumnVisibleEvent,
  ColumnMovedEvent,
  ColDef,
  ValueGetterParams
} from '@ag-grid-community/core'

import {
  GridView,
  GridViewUser,
  ColumnsMetadataMap,
  GridViewData,
  ColumnState,
  ColsDefs,
  FilterModel
} from '../types'

import _ from 'lodash'

import { filterStore } from './filterStore'
import { adaptGridViewData, convertFilterModelToV2 } from './gridVersionAdapter'
interface UpdatedColumnsVisibility {
  title: string
  field: string
  dateField: string
  valueType: string
  visible: boolean
  editable: boolean
  pinned?: boolean
}

type ValueType =
  | 'string'
  | 'number'
  | 'integer'
  | 'float'
  | 'url'
  | 'date'
  | 'dateUrl'
  | 'boolean'
  | 'time'
type ConvertedType = 'text' | 'number' | 'date' | 'select' | 'time'

const convertType = (type: ValueType): ConvertedType => {
  if (!type) return 'text'
  const startsWith = (str: string) => (type: string) => type.startsWith(str)
  switch (type) {
    case 'string':
      return 'text'
    case 'number':
      return 'number'
    case 'integer':
      return 'number'
    case 'float':
      return 'number'
    case 'url':
      return 'text'
    case 'date':
      return 'date'
    case 'dateUrl':
      return 'date'
    case 'boolean':
      return 'select'
    case 'time':
      return 'time'
    default:
      return startsWith('select')(type) ? 'select' : 'text'
  }
}

export function useGridManagement(apiName: string) {
  const availableGridViews = ref<GridView[]>([])
  const selectedGridView = ref<GridView | null>(null)
  const gridViewManagerLoaded = ref(false)
  const colsDefs = ref<ColsDefs[]>([])
  const isGridLoaded = ref(false)

  function buildColumnsStructureFromGridView(
    columnsMetadataMapArray: ColumnsMetadataMap[]
  ) {
    colsDefs.value = [
      {
        headerName: 'Row',
        valueFormatter: params => params.value,
        valueGetter: params => (params.node?.rowIndex || 0) + 1,
        filter: false,
        floatingFilter: false,
        editable: false,
        suppressMovable: true,
        pinned: 'left',
        sortable: false,
        width: 10,
        colId: 'row',
        name: 'row'
      }
    ]
    if (!selectedGridView.value) return false

    const gridViewConfig: GridViewData =
      typeof selectedGridView.value.data === 'string'
        ? JSON.parse(selectedGridView.value.data)
        : selectedGridView.value.data

    const columns = selectedGridView.value.columns || gridViewConfig.columns
    const columnsMetadataMap = columnsMetadataMapArray.reduce((acc, column) => {
      acc[column.field] = column
      return acc
    }, {})

    if (!columns) return false
    columns.forEach((column, index) => {
      if (
        !column.field ||
        (!columnsMetadataMap[column.field] && !column.isCustom)
      ) {
        console.error(
          'Column field is missing or not found in metadata map:',
          column
        )
        return false
      }

      const columnDef2Add = columnsUtils.buildSingleColumnStructureFromGridView(
        column,
        columnsMetadataMap
      )

      if (columnDef2Add.field) {
        columnDef2Add.colId = columnDef2Add.field
        columnDef2Add.isNumber =
          columnDef2Add.valueType === 'number' ||
          columnDef2Add.valueType === 'integer' ||
          columnDef2Add.valueType === 'float'
        columnDef2Add.isDate = columnDef2Add.valueType === 'date'
        columnDef2Add.isTime = columnDef2Add.valueType === 'time'

        const isSelectMultipleOptions =
          columnDef2Add.valueType.startsWith('selectMultiple')
        if (isSelectMultipleOptions) {
          columnDef2Add.isSelectMultipleOptions = true
        }
        columnDef2Add.type =
          typeof columnDef2Add.valueType === 'string'
            ? convertType(columnDef2Add.type)
            : 'text'

        if (columnDef2Add.isCustom) {
          columnDef2Add.sortable = false
          columnDef2Add.filter = false
          columnDef2Add.editable = true
        }
        if (columnDef2Add.valueType === 'function') {
          columnDef2Add.valueGetter = buildValueGetter(
            column.selectedColumns,
            column.functionName
          )
          columnDef2Add.editable = false
        }

        columnDef2Add.isSelectMultipleOptions = isSelectMultipleOptions
        columnDef2Add.filter = FilterOption
        columnDef2Add.filterParams = {
          column: {
            name: columnDef2Add.field,
            label: columnDef2Add.headerName,
            type: convertType(columnDef2Add.valueType),
            options: columnDef2Add.availableOptions || [],
            isSelectMultipleOptions,
            stringify: columnDef2Add.stringify
          },
          isGridFilter: true
        }
      }

      if (!columnDef2Add) return false

      if (!index) {
        columnDef2Add.suppressMovable = true
        columnDef2Add.pinned = 'left'
        columnDef2Add.width = 200
      }
      columnDef2Add.pinned = column.pinned ? 'left' : columnDef2Add.pinned
      // @ts-ignore
      colsDefs.value.push(columnDef2Add)
    })
    return true
  }

  function buildValueGetter(selectedColumns: string[], functionType: string) {
    return (params: ValueGetterParams) => {
      const row = params.data
      const values = selectedColumns.map(
        columnKey => +columnsUtils.getValue(row, columnKey) || 0
      )

      switch (functionType) {
        case 'sum':
          return values.reduce((acc, value) => acc + value, 0)
        case 'min':
          return Math.min(...values)
        case 'max':
          return Math.max(...values)
        case 'average':
          return values.length
            ? values.reduce((acc, value) => acc + value, 0) / values.length
            : 0
        case 'count':
          return values.filter(value => value !== 0).length
        default:
          console.error('Unsupported function type:', functionType)
          return null
      }
    }
  }

  const saveGridView = async (
    name: string | null,
    gridApi: GridApi,
    isUpdate: boolean
  ) => {
    const columnsState = gridApi.getColumnDefs()

    if (!columnsState || !columnsState.length) {
      return
    }
    try {
      const newGridView = {
        name: name,
        api_name: apiName,
        data: JSON.stringify(getCurrentConfig(columnsState)),
        position: availableGridViews.value.length
      }
      let response
      if (selectedGridView.value?.id && isUpdate) {
        response = await axios.put(
          `/my_account/grid_views/${selectedGridView.value.id}`,
          newGridView
        )
      } else {
        response = await axios.post('/my_account/grid_views', newGridView)
      }

      const gridView = response.data
      if (!isUpdate) {
        availableGridViews.value.push(gridView)
        window.history.pushState({}, '', `?grid_view_id=${gridView.id}`)
      } else {
        const index = availableGridViews.value.findIndex(
          gv => gv.id === selectedGridView.value?.id
        )
        if (index !== -1) {
          availableGridViews.value[index] = gridView
        }
      }
      onSelectedGridViewChange(gridView)
      ElNotification({
        title: 'Success',
        message: 'GridView saved successfully',
        type: 'success'
      })
    } catch (error) {
      console.error('Failed to save Grid View', error)
      ElNotification({
        title: 'Error',
        message: 'Failed to save GridView',
        type: 'error'
      })
    }
  }

  function getCurrentConfig(columnState: ColDef[]) {
    const conditions = selectedGridView.value?.conditionalFormatRules || []
    const sortModel = selectedGridView.value?.sortModel || []

    return {
      filterModel: selectedGridView.value?.filterFields,
      // columnState: columnState,
      columns: columnState
        .filter(col => col.field)
        .map(col => {
          return _.pick(col, [
            'field',
            'sortableField',
            'width',
            'editable',
            'pinned'
          ])
        }),
      sortModel: sortModel,
      conditionalFormatting: conditions
    }
  }

  const editGridView = async () => {
    if (selectedGridView.value && selectedGridView.value.editable) {
      const editedGridView = {}
      try {
        const response = await axios.put(
          `/my_account/grid_views/${selectedGridView.value.id}`,
          editedGridView
        )
        const gridView = response.data
        const index = availableGridViews.value.findIndex(
          gv => gv.id === selectedGridView.value!.id
        )
        if (index !== -1) {
          availableGridViews.value[index] = gridView
        }
        selectedGridView.value = gridView
        ElNotification({
          title: 'Success',
          message: 'Your Grid View has been Saved',
          type: 'success'
        })
      } catch (error) {
        console.error('Failed to edit Grid View', error)
        ElNotification({
          title: 'Error',
          message: 'Failed to edit GridView',
          type: 'error'
        })
      }
    }
  }

  const onSelectedGridViewChange = (newGridView: GridView) => {
    const gridViewData = newGridView.data
    const gridViewDataParsed =
      typeof gridViewData === 'string' ? JSON.parse(gridViewData) : gridViewData

    // const filterFields = gridViewDataParsed.filterModel || {}

    const gridData = adaptGridViewData(gridViewDataParsed, true)
    const newFilterFields = gridData.filterModel || {}
    const sortModel = gridData.sortModel || []
    const columnDefs = colsDefs.value
    newGridView.filterFields = newFilterFields
    const searchFields = gridViewDataParsed.searchFields || []
    selectedGridView.value = newGridView
    selectedGridView.value.searchFields = searchFields || []
    selectedGridView.value.sortModel = sortModel || []

    const conditions = gridData.conditionalFormatting || []
    selectedGridView.value.conditionalFormatRules = conditions
    filterStore.initializeFilters(newFilterFields)
    localStorage.setItem('grid_view_id', newGridView.id.toString())
  }

  const getAvailableGridViews = async () => {
    try {
      const response = await axios.get<GridView[]>(
        `/my_account/grid_views?api_name=${apiName}`
      )
      availableGridViews.value = response.data
      return availableGridViews.value
    } catch (error) {
      console.error('Failed to get Grid Views:', error)
    }
  }

  async function updateGridViewsPositions(
    availableEditableGridViews: GridView[]
  ) {
    const nonEditableGridViews = availableGridViews.value.filter(
      gv => !gv.editable
    )
    try {
      availableGridViews.value = [
        ...availableEditableGridViews,
        ...nonEditableGridViews
      ]

      const updatePromises = availableEditableGridViews.map((gv, position) =>
        axios.put(`/my_account/grid_views/${gv.id}`, { position })
      )

      await Promise.all(updatePromises)

      ElNotification({
        title: 'Success',
        message: 'Grid views positions updated successfully',
        type: 'success'
      })
    } catch (error) {
      console.error('Error updating grid views positions:', error)
      const availableGridViewsServer = await getAvailableGridViews()
      if (!availableGridViewsServer) return
      availableGridViews.value = availableGridViewsServer

      ElNotification({
        title: 'Error',
        message:
          'Error updating grid views positions. Changes have been reverted.',
        type: 'error'
      })
    }
  }

  const shareGridView = async ({
    gridViewId,
    users
  }: {
    gridViewId: string
    users: GridViewUser[]
  }) => {
    try {
      // const response = await axios.put(`/my_account/grid_views/${gridViewId}`, {
      //   grid_view: { users }
      // })
      // if (response.status === 200) {
      //   ElNotification({
      //     title: 'Success',
      //     message: 'GridView shared successfully',
      //     type: 'success'
      //   })
      // await getAvailableGridViews()

      const sharedGridView = availableGridViews.value.find(
        gv => gv.id === parseInt(gridViewId)
      )
      if (!sharedGridView || !selectedGridView.value) return
      selectedGridView.value.users = sharedGridView.users || []

      const columns = selectedGridView.value.columns
      const customColumns = columns?.filter(
        col => col.isCustom || col.field?.includes('custom_field')
      )
      const customColumnsData = await axios.post('/api/custom_fields', {
        query: {
          id_in: customColumns?.map(col => col.field?.match(/\d+/)?.[0])
        },
        additionalColumns: [
          'name',
          'description',
          'value_type',
          'options',
          'is_template',
          'active',
          'value',
          'custom_field_id',
          'fieldable_type',
          'fieldable_id',
          'to_grid_column'
        ]
      })
      console.log('customColumnsData,', customColumnsData)
      console.log('customColumnsData', customColumnsData)
      console.log('customColumns', customColumns)
      const customColumnsDataParsed = customColumnsData.data.data
      customColumnsDataParsed.forEach(async col => {
        if (!col.to_grid_column.users.includes(users)) {
          const missingUsers = users.filter(
            user => !col.to_grid_column.users.includes(user)
          )

          if (missingUsers.length && col.to_grid_column.id) {
            const response = await axios.put(
              `/api/custom_fields/${col.to_grid_column.id}`,
              {
                custom_field: {
                  users: missingUsers
                }
              }
            )
            console.log('response', response)
          }
        }
      })
    } catch (error) {
      console.error('Error sharing grid view:', error)
      ElNotification({
        title: 'Error',
        message: 'Failed to share GridView',
        type: 'error'
      })
    }
  }

  const deleteGridView = async (
    gridViewIds: number | number[]
  ): Promise<void> => {
    const ids = Array.isArray(gridViewIds) ? gridViewIds : [gridViewIds]

    try {
      const deletePromises = ids.map(id =>
        axios.delete(`/my_account/grid_views/${id}`)
      )

      const responses = await Promise.allSettled(deletePromises)

      const successfulDeletions = responses.filter(
        (response): response is PromiseFulfilledResult<any> =>
          response.status === 'fulfilled' && response.value.status === 204
      )

      if (successfulDeletions.length > 0) {
        ElNotification({
          title: 'Success',
          message: `${successfulDeletions.length} GridView(s) deleted successfully`,
          type: 'success'
        })

        availableGridViews.value = availableGridViews.value.filter(
          gv => !ids.includes(gv.id)
        )

        await getAvailableGridViews()
      }

      const failedDeletions = responses.filter(
        response => response.status === 'rejected'
      )
      if (failedDeletions.length > 0) {
        console.error('Error deleting some grid views:', failedDeletions)
        ElNotification({
          title: 'Warning',
          message: `Failed to delete ${failedDeletions.length} GridView(s)`,
          type: 'warning'
        })
      }
    } catch (error) {
      console.error('Error in deleteGridView:', error)
      ElNotification({
        title: 'Error',
        message: 'An unexpected error occurred while deleting GridView(s)',
        type: 'error'
      })
    }
  }

  async function initiateGridViewsManagement() {
    try {
      await getAvailableGridViews()
      if (!availableGridViews.value.length) {
        const emptyGridView: GridView = {
          id: 1,
          name: 'Empty Grid',
          is_public: true,
          data: JSON.stringify({ columns: [] }),
          position: 0,
          editable: false,
          updated_at: new Date().toISOString()
        }

        availableGridViews.value = []
        availableGridViews.value.push(emptyGridView)
      }

      let gridViewId = availableGridViews.value[0].id
      const urlParams = new URLSearchParams(window.location.search)
      const localStorageGridViewId = localStorage.getItem('grid_view_id')
      if (urlParams.has('grid_view_id')) {
        const urlGridViewId = urlParams.get('grid_view_id')

        if (urlGridViewId) {
          gridViewId = parseInt(urlGridViewId)
        }
      } else if (localStorageGridViewId) {
        gridViewId = parseInt(localStorageGridViewId)
      }

      const gridView = availableGridViews.value.find(gv => gv.id === gridViewId)
      const defaultGridView = gridView || availableGridViews.value[0]

      onSelectedGridViewChange(defaultGridView)
      gridViewManagerLoaded.value = true
    } catch (e) {
      console.error(e)
    }
  }

  function handleSortChanged(event: SortChangedEvent) {
    if (!selectedGridView.value) return
    const sortModel = event.columnApi
      ?.getColumns()
      ?.map(col => ({
        colId: col.getColId(),
        sort: col.getSort(),
        sortIndex: col.getSortIndex(),
        sortableField:
          (col.getColDef() as ColsDefs).sortableField || col.getColId()
      }))
      .filter(col => col.sort)
      .sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0))
    if (!sortModel) return
    selectedGridView.value.sortModel = sortModel
  }

  const onFilterChanged = (gridApi: GridApi | null) => {
    const filterModel: FilterModel | undefined = gridApi?.getFilterModel()
    if (!filterModel) return
  }

  function onFilterColumns(
    filters: GridView['filterFields'],
    gridApi: GridApi | null,
    getDataSource: (selectedGridView: GridView) => any
  ) {
    if (!selectedGridView.value || !filters) return
    selectedGridView.value.filterFields = filters
    gridApi?.setServerSideDatasource(getDataSource(selectedGridView.value))
    gridApi?.setFilterModel(filters)
    gridApi?.hidePopupMenu()
  }

  function onSearchTextChange({
    searchValue,
    selectedColumns,
    gridApi,
    getDataSource
  }: {
    searchValue: string
    selectedColumns: string[]
    gridApi: GridApi | null
    getDataSource: (selectedGridView: GridView) => any
  }) {
    if (!selectedGridView.value) return

    const searchFields = selectedColumns.map(colId => {
      const colDef = gridApi?.getColumnDef(colId) as ColsDefs

      return {
        field: colDef?.field || colId,
        value: searchValue,
        type: colDef?.valueType?.startsWith('select')
          ? 'select'
          : colDef?.valueType,
        options: colDef?.availableOptions || [],
        isSelectMultipleOptions: colDef?.isSelectMultipleOptions || false
      }
    })
    if (selectedGridView.value) {
      // @ts-ignore
      selectedGridView.value.searchFields = searchFields
    }

    gridApi?.setServerSideDatasource(getDataSource(selectedGridView.value))
  }

  function onSelectGridView(
    selectedGridView: GridView,
    gridApi: GridApi | null
  ) {
    isGridLoaded.value = false
    if (selectedGridView.id) {
      window.history.pushState({}, '', `?grid_view_id=${selectedGridView.id}`)
    }
    gridApi?.setColumnDefs([])
    colsDefs.value = []
    onSelectedGridViewChange(selectedGridView)
  }

  const onColumnVisibilityChanged = (event: ColumnVisibleEvent) => {
    const column = event?.column
    if (!column) return

    const colDef = column.getColDef()
    const colField = colDef.field
    const isVisible = column.isVisible()

    if (!colField || !selectedGridView.value?.columns) return

    if (isVisible) {
      const columnExists = selectedGridView.value.columns.some(
        col => col.field === colField
      )
      if (!columnExists) {
        const newColumn: ColsDefs = {
          field: colField,
          width: colDef.width || 200,
          editable: !!colDef.editable,
          headerName: colDef.headerName || colField,
          name: colField
        }
        selectedGridView.value.columns.push(newColumn)
      }
    } else {
      selectedGridView.value.columns = selectedGridView.value.columns.filter(
        col => col.field !== colField
      )
      colsDefs.value = colsDefs.value.filter(col => col.field !== colField)
      if (filterStore.hasFilter(colField)) {
        filterStore.removeFilter(colField)
      }

      const conditionalFormattingRules =
        selectedGridView.value.conditionalFormatRules || []
      const ruleToRemove = conditionalFormattingRules.find(
        rule => rule.column === colField
      )
      if (ruleToRemove) {
        selectedGridView.value.conditionalFormatRules =
          conditionalFormattingRules.filter(rule => rule.column !== colField)
      }
    }
  }
  const onColumnsVisibilityChanged = (
    updatedColumnsVisibility: UpdatedColumnsVisibility[],
    gridApi: GridApi
  ) => {
    if (!selectedGridView.value) {
      console.warn('No grid view selected. Cannot update column visibility.')
      return
    }

    const updatedColumnState = updatedColumnsVisibility.filter(
      col => col.visible
    )

    selectedGridView.value = {
      ...selectedGridView.value,
      columns: updatedColumnState.map(col => {
        return {
          ...col,
          field: col.field,
          title: col.title,
          valueType: col.valueType,
          editable: col.editable,
          headerName: col.title,
          pinned: col.pinned ? 'left' : undefined,
          visible: col.visible,
          name: col.field
        }
      })
    }

    const visibleColumns = updatedColumnsVisibility.filter(col => col.visible)
    const visibleColumnFields = visibleColumns.map(col => col.field)
    for (const field in filterStore.pendingFilters.value) {
      if (
        filterStore.hasFilter(field) &&
        !visibleColumnFields.includes(field)
      ) {
        filterStore.removeFilter(field)
      }
    }

    gridApi.refreshHeader()
    gridApi.refreshCells({ force: true })
  }

  const updateColumnOrder = (newOrder: string[]) => {
    if (
      !selectedGridView.value?.columns ||
      !selectedGridView.value.columns.length
    )
      return

    const updatedColsDefs = newOrder
      .map(colId =>
        colsDefs.value.find(col => col.field === colId || col.colId === colId)
      )
      .filter(Boolean) as ColsDefs[]

    colsDefs.value = updatedColsDefs
  }

  const debouncedUpdateColumnOrder = _.debounce(updateColumnOrder, 300)

  const onColumnsOrderChanged = (event: ColumnMovedEvent) => {
    const allGridColumns = event.columnApi.getAllGridColumns()
    const newOrder = allGridColumns.map(col => col.getColId())

    debouncedUpdateColumnOrder(newOrder)
  }

  return {
    availableGridViews,
    selectedGridView,
    colsDefs,
    isGridLoaded,
    saveGridView,
    editGridView,
    onSelectedGridViewChange,
    updateGridViewsPositions,
    shareGridView,
    deleteGridView,
    initiateGridViewsManagement,
    buildColumnsStructureFromGridView,
    handleSortChanged,
    onFilterChanged,
    onFilterColumns,
    onSearchTextChange,
    onSelectGridView,
    onColumnsVisibilityChanged,
    onColumnVisibilityChanged,
    onColumnsOrderChanged,
    getAvailableGridViews
  }
}
